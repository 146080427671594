<template>
  <div class="md-layout md-alignment-top-center">
    <md-card class="md-layout-item md-size-50 md-small-size-100">
      <md-card-header>
        <div class="md-title">{{ config.title.toUpperCase() }}</div>
      </md-card-header>
      <md-card-content>
        <table style="width: 100%">
          <tr>
            <td width="49%" align="right">
              <img alt="logo" style="height: 120px; width: auto" src="@/assets/logo_blue.jpg">
            </td>
            <td>&nbsp;</td>
            <td width="49%" align="left">
              <h2>LICENSE: {{ this.plate }}</h2>
              <h2 v-if="paid">PAID: {{ (new Date()) | formatDateOnly }}</h2>
              <h2 v-if="paid">AMOUNT {{ this.paidAmount | currency }}</h2>
            </td>
          </tr>
        </table>
        <div><br/></div>
        <div>
          <h3><u>RECEIPT</u></h3>
          <p>
            This page is your receipt for payment of amount owed on the listed license plate, through <u>{{ config.url }}</u>
          </p>
        </div>
        <div><br/></div>
        <div><br/><br/></div>
        <div>
          (print this page for your records)
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'letterView',
  data () {
    return {
      form: {
        plate: null
      },
      config: {
        title: process.env.VUE_APP_TITLE,
        url: process.env.VUE_APP_HOME_URL
      },
      sending: false,
      success: false
    }
  },
  computed: {
    plate () {
      return this.$store.getters.plate
    },
    paid () {
      return this.$store.getters.paid
    },
    paidAmount () {
      return this.$store.getters.paidAmount
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
